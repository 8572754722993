<template>
  <div>
    <div class='mb-12'>
      <label class='inline-block mr-10'>그룹 번호</label>
      <input type='number' class='form-input' v-model='drilldownGroupNo' />
    </div>
    <div class='mb-12'>
      <label class='inline-block mr-10'>그룹 명</label>
      <input type='text' class='form-input' v-model='drilldownGroupName' />
    </div>
    <div class='mb-12'>
      <label class='inline-block mr-10'>사용자</label>
      <user-search
        :user-ids='drilldownGroupUsers'
        :user-names='drilldownGroupUserNames'
        :is-multiple='true'
        @update-selected-user='updateSelectedUsers' />
    </div>
    <div class='mb-12'>
      <label class='inline-block mr-10'>Dashboard 권한</label>
      <v-select
        v-model='selectedDashboards'
        label='dashboard_view_name'
        :options='sharedDashboardViews'
        :close-on-select='false'
        multiple
        class='w-full'>
        <template v-slot:option='option'>
          {{ option.dashboard_view_name }}
        </template>
      </v-select>
    </div>
    <button
      class='mr-4 inline-block py-3 px-16 text-white rounded-md font-bold border border-transparent hover:border-blue-500 hover:shadow-md'
      style='background-color: #223645;'
      @click='submitUserGroup'>
      저장
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import UserSearch    from '@/components/UserSearch.vue'

export default {
  name: 'UserGroupDetails',
  components: {
    UserSearch
  },
  computed: {
    ...mapFields('users', {
      drilldownGroup: 'drilldownUserGroup',
      drilldownGroupNo: 'drilldownUserGroup.group_no',
      drilldownGroupName: 'drilldownUserGroup.group_name',
      drilldownGroupUsers: 'drilldownUserGroup.user_ids',
      drilldownGroupUserNames: 'drilldownUserGroup.user_names',
      drilldownGroupDashboards: 'drilldownUserGroup.dashboard_view_ids',
    }),
    ...mapGetters('dashboardViews', [
      'sharedDashboardViews',
    ]),
    hasUserGroup () {
      return this.drilldownGroup.id > 0
    },
    selectedDashboards: {
      get () {
        if (this.drilldownGroupDashboards) {
          return this.sharedDashboardViews.filter(view => this.drilldownGroupDashboards.includes(view.id))
        } else {
          return []
        }
      },
      set (dashboardViews) {
        this.drilldownGroupDashboards = dashboardViews.map(view => view.id)
      }
    },
  },
  methods: {
    ...mapActions('dashboardViews', [
      'getDashboardViews',
     ]),
    ...mapActions('users', [
      'createUserGroup',
      'updateUserGroup',
    ]),
    submitUserGroup () {
      if (this.hasUserGroup) {
        this.updateUserGroup(this.drilldownGroup)
      } else {
        this.createUserGroup(this.drilldownGroup)
      }
    },
    updateSelectedUsers (ids, nameAndEmails) {
      this.drilldownGroupUsers = ids
      this.drilldownGroupUserNames = nameAndEmails
    }
  },
  mounted () {
    this.getDashboardViews()
  },
}
</script>

<style lang='scss' scoped>

.form-label {
  @apply block font-normal mb-2 text-gray-600 text-sm tracking-wide uppercase;
}

</style>
